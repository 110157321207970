<template>
  <v-card class="mx-auto">
    <v-list
      :class="
        $vuetify.breakpoint.mobile
          ? 'text-right mx-3 three-line'
          : 'text-right mx-6 pb-0 pt-0 three-line'
      "
    >
      <v-list-item three-line class="mb-0">
        <v-list-item-avatar
          class="d-flex justify-center"
          color="accent"
          :size="$vuetify.breakpoint.mobile ? 120 : 120"
        >
          <img v-if="user.graphPhoto !== null" v-bind:src="user.graphPhoto" />
          <span v-else class="white--text">{{ shortUserName }}</span>
        </v-list-item-avatar>

        <v-list-item-content class="pb-0">
          <v-list-item-title class="text-overline"
            
            >{{ showUserJobTitle() }}</v-list-item-title
          >

          <v-list-item-title
            :class="$vuetify.breakpoint.mobile ? 'text-body-2 mb-2' : 'text-h5'"
          >
            {{ user.displayName }}
          </v-list-item-title>

          <v-list-item-subtitle
            :class="$vuetify.breakpoint.mobile ? 'text-caption ml-0' : 'ml-2'"
          >
            {{ user.mail }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <a :href="`mailto:${user.mail}`">
                  <v-icon :small="$vuetify.breakpoint.mobile" v-bind="attrs" v-on="on" color="info"
                    >mdi-email</v-icon
                  >
                </a>
              </template>
              <span>Send email</span>
            </v-tooltip></v-list-item-subtitle
          >

          <v-list-item-subtitle
            :class="$vuetify.breakpoint.mobile ? 'text-caption ml-2' : 'ml-2'"
            >{{ user.businessPhones[0] }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :small="$vuetify.breakpoint.mobile"
                  v-bind="attrs"
                  v-on="on"
                  color="secondary"
                >
                  {{ "mdi-phone-classic" }}
                </v-icon>
              </template>
              <span>Make a call</span>
            </v-tooltip></v-list-item-subtitle
          >
          <v-list-item-subtitle
            :class="$vuetify.breakpoint.mobile ? 'text-caption ml-2' : 'ml-2'"
          >
            {{ user.mobilePhone }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <a :href="getCallUrl(user)">
                  <v-icon
                    :small="$vuetify.breakpoint.mobile"
                    v-bind="attrs"
                    v-on="on"
                    color="vicyellow"
                  >
                    {{ "mdi-cellphone" }}
                  </v-icon>
                </a>
              </template>
              <span>Make a call</span>
            </v-tooltip></v-list-item-subtitle
          >

          <v-list-item-subtitle
            :class="$vuetify.breakpoint.mobile ? 'text-caption ml-2' : 'ml-2'"
          >
            {{ user.officeLocation
            }}<v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <a :href="showGoogleMapsLocation()" target="_blank" rel="noopener noreferrer">
                  <v-icon
                    :small="$vuetify.breakpoint.mobile"
                    v-bind="attrs"
                    v-on="on"
                    color="warning"
                  >
                    mdi-office-building-marker
                  </v-icon>
                </a>
              </template>
              <span>Lookup office location in Google Maps</span>
            </v-tooltip></v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-expand-transition>
      <div>
        <v-card-text>
          <v-chip-group>
            <v-chip
              v-show="showSkills !== undefined && user.skills && user.skills.length > 0"
              small
              v-for="(skill, index) in user.skills"
              :key="skill"
              text-color="white"
              :color="getTagColor(index)"
              @click="$emit('chip-clicked', skill)"
            >
              {{ getTagText(skill) }}
            </v-chip>
            <v-chip
              v-show="showSkills !== undefined && user.skills && user.skills.length == 0"
              small
              text-color="white"
              color="info"
              @click="editProfile(user.id)"
            >
              Add some skills on your profile page!
            </v-chip>
          </v-chip-group>
        </v-card-text>
      </div>
    </v-expand-transition>
    <!-- <v-divider></v-divider> -->
    <!-- <v-card-actions>
      <v-spacer></v-spacer>
      <a :href="`callto:${user.mail}`">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small rounded color="accent" class="ma-1 white--text" v-bind="attrs" v-on="on">
              CALL
              <v-icon right dark> mdi-microsoft-teams </v-icon>
            </v-btn>
          </template>
          <span>Call user in Microsoft Teams</span>
        </v-tooltip>
      </a>
    </v-card-actions> -->
  </v-card>
</template>

<script>
import graph from "../plugins/graph";
import User from "../models/User";

// import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    tagColors: ["accent", "secondary", "vicyellow", "info", "warning"],
    show: false,
    selectProperties: ["id", "skills"],
  }),
  props: ["userId", "showSkills"],

  async created() {
    // this.fetchAvatar(this.userId);

    const result = await graph.getUserPhoto(this.user.id);
    // console.log(result)
    if (result) {
      // console.log(result);
      await User.update({ where: this.user.id, data: { graphPhoto: result } });
    }

    // if (this.showSkills) {
    //   this.fetchUserDetailsById({
    //     userId: this.userId,
    //     selectProperties: this.selectProperties,
    //   });
    // }
  },

  watch: {},
  computed: {
    user() {
      return User.query().where("id", this.userId).first();
    },
    userAvatar() {
      return this.user.graphPhoto;
    },
    imageAsBase64() {
      return this.user.graphPhoto?.img;
    },
    shortUserName() {
      if (this.user !== undefined) {
        return this.user.displayName.split("@", 1)[0];
      } else {
        return "";
      }
    },
    // userAccount() {
    //   return this.user.userAccount;
    // },
  },
  methods: {
    // ...mapActions("user", ["fetchAvatar", "fetchUserDetailsById"]),
    getTagColor(index) {
      return index <= 4 ? this.tagColors[index] : this.tagColors[index % 5];
    },
    getTagText(skill) {
      return skill.length > 30 ? skill.slice(0, 30).concat("...") : skill;
    },
    composeNewMail(userMail) {
      parent.location = "mailto:abc@abc.com";
    },
    editProfile(userId) {
      window.open(`https://eur.delve.office.com/?u=${userId}&v=editprofile`, "_blank").focus();
    },
    showGoogleMapsLocation() {
      if (this.user.officeLocation == "Hoogvliet Rotterdam") {
        return "https://goo.gl/maps/8i5CufWKxYK4TGzF9";
      } else if (this.user.officeLocation == "Sint-Oedenrode") {
        return "https://goo.gl/maps/NoYmKaz2w6zjTqqD7";
      } else if (this.user.officeLocation == "Velsen-Noord") {
        return "https://goo.gl/maps/ADBi99dvo7GBkL9c9";
      } else if (this.user.officeLocation == "Groningen") {
        return "https://goo.gl/maps/w6CFoaJP81hQYP6y9";
      } else if (this.user.officeLocation == "Chennai") {
        return "https://goo.gl/maps/7abSxxLHWr3VQqTv8";
      }
    },
    showUserJobTitle() {
      let formattedUserJobTitle;

      if (this.user.jobTitle) {
        formattedUserJobTitle = this.user.jobTitle.replace("Electrical & Instrumentation", "E&I");
        formattedUserJobTitle = formattedUserJobTitle.replace("Architectuur & Bouwkunde", "A&B");
        return formattedUserJobTitle;
      }
      return null;
    },

    getCallUrl(user) {
      return user.mobilePhone ? `tel:${user.mobilePhone}` : `tel:${user.businessPhones[0]}`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding-top: 0px !important;
}

a:link {
  text-decoration: none;
}
</style>
